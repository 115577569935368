.LoadingScreen{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.9;
    z-index: 1;
}
.LoadingIcon{
    margin-top: 10%;
}