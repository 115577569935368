.mediaCardsContainer{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.formContainer{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formElement{
    width: max-content;
    margin: 20px auto auto auto!important;
    line-height: 2.8!important;
}

.selectedCard{
    width: 100%;
    opacity: 1;
}
.notSelectedCard{
    width: 100%;
    opacity: 0.8;
}

.dataTable{
    margin: auto;
}

.deleteIcon{
    cursor: pointer;
}