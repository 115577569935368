.card{
    /* max-width: 345px; */
    width: auto;
    height: 15vw;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.media{
    /* max-width: 345px;
    height: 200px; */
    width: auto;
    height: 80%;
    background-size: contain;
    box-shadow: 5px 3px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 3px 5px 0px rgba(0,0,0,0.75);
}

.deleteIcon{
    margin: auto;
}

.selectedCard{
    opacity: 1;
}
.notSelectedCard{
    opacity: 0.6;
}

@media (max-aspect-ratio: 1/1){
    .media{
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }

    .card{
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
}