.mediaCardsContainer{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}


@media (max-aspect-ratio: 1/1){
    .mediaCardsContainer{
        flex-direction: column;
        justify-content: space-between;
    }
}