.formContainer{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formElement{
    width: max-content;
    margin: 20px auto auto auto!important;
    line-height: 2.8!important;
}