.Container {
    outline: none;
    border-radius: 25px;
    background-color: #333333;
    color: #fff;
    font: inherit;
    padding-top: 30px;
    padding-bottom: 60px ;
    display: block;
    width: 50%;
    min-width: 300px;
    margin: auto;
    box-sizing: border-box;
}