.formContainer{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formElement{
    width: max-content;
    margin: 10px auto auto auto!important;
    line-height: 2.8!important;
}

.addCardButton{
    width: auto!important;
    height: 50px!important;
    margin-top: 70px!important;
}
.addCardButtonContainer{
    width: auto!important;
    margin-left: auto;
    margin-right: auto;
}
.emptyCardsMessage{
    margin: auto;
}
