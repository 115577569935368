.InputElement {
    width: 80%;
    margin: 20px auto auto auto!important;
    border-radius: 10px!important;
    background-color: #fff;
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}